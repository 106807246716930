<template>
    <vx-card title="Import Inter Warehouse">
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col w-full flex items-center">
                <vs-button
                    class="mt-2"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-arrow-left"
                    @click="handleBack()"
                >Back</vs-button>
                <vs-button
                    class="mt-2 ml-2"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-download"
                    @click="handleDownloadTemplate()"
                >Template Download</vs-button>
                <vs-button
                    class="mt-2 ml-2"
                    color="success"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="$refs.file.click()"
                >New Import</vs-button>
                <input type="file" ref="file" accept=".xls,.xlsx" @change="handleImport" style="display: none;" :key="fileKey"/>
            </div>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Filter</h4>
                <hr>
                <br>
                <!-- ... -->
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Src. Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <SrcWarehouse @selected="(val) => { this.selectedSrcWarehouse = val }"></SrcWarehouse>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Dest. Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Warehouse @selected="(val) => { this.selectedWarehouse = val }"></Warehouse>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Import Status</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select
                            :filterable="true"
                            :options="statusOptions"
                            v-model="selectedStatus"
                            label="name"
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-button
                            @click="handleFilter()"
                            size="medium"
                            color="primary"
                            icon-pack="feather"
                            icon="icon-search"
                            title="Filter"
                        >Filter</vs-button>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Pay Attention</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-6 ml-5 mr-5">
                        <p><b>1. Relocation Type (STO Type)</b></p>
                        <p><strong>By Request :</strong> adalah perpindahan stok barang dari gudang asal ke gudang tujuan sesuai permintaan dari gudang tujuan. Shipment cost akan dibebankan pada gudang tujuan.</p>
                        <p><strong>Normalisasi Stock :</strong> adalah proses penyesuaian atau penyelarasan jumlah stok antar gudang. Proses ini bertujuan untuk mengoptimalkan distribusi barang sehingga setiap gudang memiliki jumlah stok yang cukup sesuai dengan permintaan dan kebutuhan operasional. Shipment cost akan dibebankan pada gudang asal.</p>
                        <p><strong>Alokasi :</strong> adalah jenis perpindahan stok barang dari gudang asal ke gudang tujuan dengan biaya pengiriman dibebankan pada gudang yang ditentukan pada kolom <b><i>imposition</i></b>.</p>
                    </div>
                    <div class="vx-row mb-6 ml-5 mr-5">
                        <p><b>2. Document Creator</b></p>
                        <p>Proses import document hanya bisa dilakukan oleh akun yang sudah terpasang pada warehouse terkait. Tidak bisa dilakukan oleh user level HO.</p>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <vs-tabs v-model="activeTab">
                    <vs-tab label="Import">
                        <table-log-import
                            :draw="draw"
                            :src-warehouse="this.selectedSrcWarehouse"
                            :warehouse="this.selectedWarehouse"
                            :status="this.selectedStatus ? this.selectedStatus.name : ''"
                            @logImportLine="handleLogImportLine"
                        ></table-log-import>
                    </vs-tab>
                    <vs-tab label="Import Line">
                        <table-log-import-line
                            :draw="drawLine"
                            :src-warehouse="this.selectedSrcWarehouse"
                            :warehouse="this.selectedWarehouse"
                            :status="this.selectedStatus ? this.selectedStatus.name : ''"
                            :logImportID="this.logImportID"
                        ></table-log-import-line>
                    </vs-tab>
                </vs-tabs>
            </div>
        </div>
    </vx-card>
</template>

<script>
import SrcWarehouse from "./component/WarehouseV2.vue";
import Warehouse from "./component/WarehouseV3.vue";
import moment from "moment";
import vSelect from "vue-select";
import TableLogImport from "./tableLogImport.vue";
import TableLogImportLine from "./logImportLine.vue";
export default {
    components: {
        SrcWarehouse,
        Warehouse,
        vSelect,
        TableLogImport,
        TableLogImportLine,
    },
    data() {
        return {
            fileKey: 0,
            draw: 0,
            drawLine: 0,
            activeTab: 0,
            logImportID: 0,
            now: "",
            selectedSrcWarehouse: [],
            selectedWarehouse: [],
            statusOptions: [
                {
                    name: "All",
                },
                {
                    name: "Pending",
                },
                {
                    name: "Imported",
                },
                {
                    name: "Failed",
                }
            ],
            selectedStatus: null,
        }
    },
    mounted() {
        this.selectedStatus = this.statusOptions[0]
    },
    methods: {
        handleBack() {
            this.$router.push({
                name: this.$store.state.outbound.simpleSto.baseRouterName,
            });
        },
        handleImport(e) {
            this.$vs.dialog({
                type: "confirm",
                title: `Import from ${e.target.files[0].name}`,
                text: `This action CANNOT be undone, CANCEL to change the file or ACCEPT to continue`,
                accept: () => {
                    this.$vs.loading();
                    var formData = new FormData();
                    formData.append("file", e.target.files[0])

                    this.$http.post("/api/wms/v1/outbound/simple-sto/import-interwarehouse", formData)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.$vs.dialog({
                                type: "alert",
                                color: "success",
                                title: `Success`,
                                text: resp.message
                            });
                            this.handleFilter()
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                type: "alert",
                                color: "danger",
                                title: `Unauthorized`,
                                text: resp.message,
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        this.$vs.dialog({
                            type: "alert",
                            color: "danger",
                            title: `Error`,
                            text: error,
                        });
                    });
                }
            })

            this.fileKey++
        },
        handleDownloadTemplate() {
            var fileTitle = "IMPORT_INTER_WAREHOUSE"

            this.$vs.loading();
            this.$http.get("/api/wms/v1/outbound/simple-sto/export-template", {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/octet-stream",
                },
            })
            .then(resp => {
                if (resp.status == "error") {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        type: "alert",
                        color: "danger",
                        title: `An error occured`,
                        text: resp.message,
                    });
                } else {
                    this.$vs.loading.close();
                    this.now = moment().tz("Asia/Jakarta").format("YYYY-MM-DD_HH-mm-ss");
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;

                    fileLink.setAttribute(
                        "download",
                        fileTitle.toUpperCase()+"-"+this.now+".xlsx"
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `An error occured`,
                    text: error,
                });
            });
        },
        handleFilter() {
            this.draw++
            this.drawLine++
        },
        handleLogImportLine(id) {
            this.activeTab = 1;
            this.logImportID = id;
        },
    }
}
</script>